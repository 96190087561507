import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  BugAntIcon,
  RectangleStackIcon,
  XMarkIcon,
  ArrowRightIcon,
  CheckBadgeIcon
} from '@heroicons/react/20/solid'
import clsx from 'clsx'
import {
  Header2,
  Header3,
  Header4,
  Pill,
  PSmallFaded,
  PSmallStrong
} from './Type'
import { useQuery } from '@tanstack/react-query'

const jiraToTailwindColorMap = {
  "color_1": "stone",
  "color_2": "red",
  "color_3": "yellow",
  "color_4": "green",
  "color_5": "blue",
  "color_6": "indigo",
  "color_7": "purple",
  "color_8": "pink",
  "color_9": "rose",
  "color_10": "lime",
  "color_11": "emerald",
  "color_12": "teal",
  "color_13": "cyan",
  "color_14": "sky",
  "color_15": "violet",
  "color_16": "fuchsia",
  "color_17": "amber",
  "color_18": "orange"
}

export const IssueIcon = ({ type }) => {
  let color = 'bg-slate-700'
  switch (type) {
    case 'Bug':
      color = 'bg-red-700'
      break
    case 'Story':
      color = 'bg-green-700'
      break
    case 'Task':
      color = 'bg-blue-700'
      break
    case 'Data Changes':
      color = 'bg-purple-700'
      break
  }
  let IssueIcon = BugAntIcon
  if (type != 'Bug') {
    IssueIcon = RectangleStackIcon
  }

  return (
    <div>
      <div className={clsx('float-right rounded-md p-1', color)}>
        <IssueIcon className='h-3 w-3 text-white' aria-hidden='true' />
      </div>
    </div>
  )
}

export const IssueCard = ({ issue, sprintId, removed }) => {
  let epicColor = "gray" // default color
  if (issue.epic && issue.epic.color && issue.epic.color.key) {
    epicColor = jiraToTailwindColorMap[issue.epic.color.key] || "gray"
  }
  const {
    data: addedByData, isLoading: addedByLoading
  } = useQuery({
    queryKey: [`/sprints/${sprintId}/issues/added-by?keys=${issue.key}`],
    staleTime: 6000000,
    enabled: !issue.startedWith
  })

  const getAddedByData = () => {
    if (addedByData && addedByData[0]) {
      return `Added by: ${addedByData[0].addedByName}`
    } else {
      return `Added by: ${issue.creator.name}`
    }
  }

  const { data: issueStatus, isError, error } = useQuery({
    queryKey: [`/issues/${issue.id}/status`],
    staleTime: 6000000,
    enabled: removed, // Only run this query if the issue is marked as removed
    retry: false, // Do not retry after a failure
  })

  let removedLabel = 'Removed'
  let removedColor = ''

  if (isError) {
    removedLabel = 'Error'
    removedColor = 'red-900'
  } else if (issueStatus && issueStatus.status === 404) {
    removedLabel = 'Deleted'
    removedColor = 'red-500'
  } else if (issueStatus && issueStatus.state) {
    removedLabel = 'Removed'
    removedColor = 'orange-500'
  }


  return (

    <a
      className={clsx(
        'relative col-span-1 rounded-lg border-2 p-4 text-center shadow shadow-slate-300 hover:bg-slate-50',
        issue.flagged ? 'bg-yellow-100' : 'bg-white',
        issue.startedWith ? 'border-blue-300 ' : 'border-slate-500 ',
        removed ? 'border-red-500' : ''
      )}
      href={`https://cnsgitbits.atlassian.net/browse/${issue.key}`}
      target='_blank'
      key={issue.key}
    >
      {!issue.startedWith && (
        <div className='absolute top-0 right-0 mt-[-13px] mr-[-2px] bg-slate-500 text-white text-xs p-1 rounded px-2'>
          <strong>{getAddedByData()}</strong>
        </div>
      )}
      {removed && (
        <div className={`absolute top-0 right-0 mt-[-13px] mr-[-2px] bg-${removedColor} text-white text-xs p-1 rounded px-2`}>
          <strong>{removedLabel}</strong>
        </div>
      )}
      <div className='flex items-center'>
        <div className='flex items-center'>
          <div>
            <IssueIcon type={issue.type} />
          </div>
          <div className='ml-2 text-left'>
            <PSmallStrong text={issue.key} />
          </div>
          {issue.resolution && (
            <span className={clsx(
              'inline-flex px-1 text-sm font-medium',
              issue.isPreviouslyReleased ? 'text-gray-500' : 'text-green-600'
            )}>
              <CheckBadgeIcon
                className={clsx(
                  'mr-1 h-5 w-5',
                  issue.isPreviouslyReleased ? 'text-gray-500' : 'text-green-600'
                )}
                aria-hidden='true'
              />
            </span>
          )}
          {issue.isPreviouslyReleased && (
            <div className='inline-flex text-xs font-light text-gray-500'>Previously Counted</div>
          )}
        </div>
        <div className='flex-grow text-right'>
          {issue.status != 'Done' && (
            <Pill
              text={issue.status}
              color={issue.status == 'Backlog' ? 'slate' : 'blue'}
            />
          )}
        </div>
      </div>
      <div className='mt-4 text-left text-sm overflow-hidden h-16'>
        {issue.summary}
      </div>

      <div className='mt-4 text-left text-sm overflow-hidden overflow-ellipsis whitespace-nowrap'>
        <Pill text={issue.epic ? issue.epic.name : 'No Epic'} color={epicColor} />
      </div>
      <div className='mt-3'>
        <div className='flex place-items-center'>
          <div className='flex-grow text-left'>
            <PSmallFaded text={issue.owner ? issue.owner.name : 'No Owner'} />
          </div>
          <div className={clsx(
            'text-right text-sm ',
            issue.isPreviouslyReleased ? 'text-gray-300' : ''
          )}>
            {issue.startEstimate
              ? `${issue.startEstimate} → ${issue.estimate} ${issue.estimate == 1 ? 'Point' : 'Points'}`
              : issue.estimate !== null && issue.estimate !== undefined
                ? `${issue.estimate} ${issue.estimate == 1 ? 'Point' : 'Points'}`
                : issue.type == 'Bug'
                  ? ''
                  : 'No Estimate'}
          </div>
        </div>
        {issue.creator && (
          <div className='text-left text-xs text-slate-400'>
            <i>Created by: {issue.creator.name}</i>
          </div>
        )}
      </div>
    </a>
  )
}

export const IssuesPanel = ({ title, categories, isOpen, setIsOpen, sprintId }) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-10'
        onClose={() => setIsOpen(false)}
      >
        <div className='fixed inset-0 bg-black/30' aria-hidden='true' />
        <div className='fixed inset-0' />

        <div className='fixed inset-0 overflow-hidden'>
          <div className='absolute inset-0 overflow-hidden'>
            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16'>
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-500 sm:duration-700'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-500 sm:duration-700'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                <Dialog.Panel className='pointer-events-auto w-screen max-w-2xl'>
                  <div className='flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl'>
                    <div className='mb-6 border-b-2 border-sky-100 px-4 sm:px-6'>
                      <div className='flex items-start justify-between'>
                        <Dialog.Title>
                          <Header3 title={title} />
                        </Dialog.Title>
                        <div className='ml-3 flex h-7 items-center'>
                          <button
                            type='button'
                            className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                            onClick={() => setIsOpen(false)}
                          >
                            <span className='sr-only'>Close panel</span>
                            <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                          </button>
                        </div>
                      </div>
                    </div>
                    {categories.map(category => {
                      if (category.issues && category.issues.length) {
                        category.issues.sort((a, b) => (a.id > b.id ? 1 : -1))
                        return (
                          <div
                            className='relative mb-6 flex-1 px-4 sm:px-6'
                            key={category.name}
                          >
                            <Header4 title={category.name} />
                            <div className='grid grid-cols-1 gap-5 sm:grid-cols-2'>
                              {category.issues.map(issue => (
                                <IssueCard
                                  issue={issue}
                                  key={issue.key + ' - ' + category.name}
                                  sprintId={sprintId}
                                />
                              ))}
                            </div>
                          </div>
                        )
                      }
                    })}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
