import { useQuery } from '@tanstack/react-query'
import { Loading, Error } from '../../Elements//Indicators'
import { useParams } from 'react-router-dom'
import { PageHeader } from '../../Elements//PageHeader'
import { useState } from 'react'
import { SimpleStats } from '../../Elements//Stats'
import { UserProgressCard } from '../../Elements//Cards'
import { Header3 } from '../../Elements//Type'

const Users = ({ users }) => {
  return (
    <ul
      role='list'
      className='grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'
    >
      {users.map(user => {
        user.resolvedPoints = user.report.resolvedPoints
        user.resolvedBugs = user.report.resolvedBugs
        user.startPoints = user.report.startPoints
        user.startBugs = user.report.startBugs
        return <UserProgressCard user={user} key={user.id} />
      })}
    </ul>
  )
}

const SprintDetails = () => {
  const [hasAnalysis, setHasAnalysis] = useState(false)
  const { projKey, boardId, sprintId } = useParams()
  const {
    isLoading,
    error,
    data: sprint
  } = useQuery({
    queryKey: [`/sprints/${sprintId}?includeUserReports=true`],
    staleTime: 600000
  })

  if (isLoading) {
    return <Loading message={`Loading sprint ${sprintId}.`} />
  }

  if (error) {
    return <Error title={'Error Loading Sprint'} message={error.message} />
  }

  const headerConfig = {
    pageTitle: sprint.name,
    backLink: {
      label: 'Back',
      to: '/projects'
    },
    breadcrumb: [
      {
        label: 'Projects',
        to: '/projects'
      },
      {
        label: 'Project Details',
        to: `/projects/${projKey}`
      },
      {
        label: `Sprint ${sprintId}`
      }
    ],
    rightComponent: (
      <>
        {sprint.state != 'active' && (
          <p className='mt-2 text-sm text-gray-500'>
            {new Date(sprint.startDate).toLocaleDateString('en-US', {
              weekday: 'short',
              day: 'numeric',
              month: 'short'
            })}{' '}
            to{' '}
            {new Date(sprint.endDate).toLocaleDateString('en-US', {
              weekday: 'short',
              day: 'numeric',
              month: 'short'
            })}
          </p>
        )}

        {sprint.state == 'active' && (
          <p className='mt-2 text-sm text-green-600'>
            Active, started on{' '}
            {new Date(sprint.startDate).toLocaleDateString('en-US', {
              weekday: 'short',
              day: 'numeric',
              month: 'short'
            })}
          </p>
        )}
      </>
    )
  }

  const getStats = () => {
    let delivered = sprint.report.resolvedPoints
      ? sprint.report.resolvedPoints / sprint.report.startPoints
      : 0

    return [
      {
        name: 'Points',
        value: `${sprint.report.resolvedPoints} / ${sprint.report.startPoints}`
      },
      {
        name: 'Bugs',
        value: `${sprint.report.resolvedBugs} / ${sprint.report.startBugs}`
      },
      {
        name: 'Commitment Delivered',
        value: Number(delivered).toLocaleString(undefined, {
          style: 'percent',
          minimumFractionDigits: 2
        })
      }
    ]
  }

  return (
    <>
      {headerConfig && <PageHeader config={headerConfig} />}
      <main className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
        <SimpleStats title={'Summary'} stats={getStats()} />
        <div className='mt-4'>
          <Header3 title={'By User'} />
          <Users users={sprint.users} />
        </div>
      </main>
    </>
  )
}

export default SprintDetails
