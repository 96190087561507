import { useMutation, useQuery } from '@tanstack/react-query'
import { Loading, Error } from '../../Elements//Indicators'
import { useParams } from 'react-router-dom'
import { PageHeader } from '../../Elements//PageHeader'
import { Header3 } from '../../Elements//Type'
import { UserProgressCard } from '../../Elements//Cards'
import {
  SprintProgressCard,
  DeliverySummary,
  Changes
} from '../../Elements//SprintBlocks'
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import { useApi } from '../../AppContext'
import { useEffect } from 'react'

const Users = ({ users, analysis }) => {
  analysis.forEach(userAnalysis => {
    const user = users.find(user => user.id == userAnalysis.id)
    if (user) {
      userAnalysis.avatar = user.avatar
      userAnalysis.role = user.role
    }
  })

  users.sort((a, b) => (a.id > b.id ? -1 : 1))

  return (
    <ul
      role='list'
      className='grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'
    >
      {analysis.map(user => (
        <UserProgressCard user={user} key={user.id} link={`user/${user.id}`} />
      ))}
    </ul>
  )
}

const Notes = ({ sprintId }) => {
  const apiUrl = useApi().main
  const {
    isLoading,
    error,
    data: note
  } = useQuery({
    queryKey: [`/sprints/${sprintId}/notes`]
  })
  const notesMutation = useMutation({
    mutationFn: notes => {
      return fetch(`${apiUrl}/sprints/${sprintId}/notes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(notes)
      })
    }
  })

  let debounceTimer
  const debounce = (callback, time, ...args) => {
    window.clearTimeout(debounceTimer)
    debounceTimer = window.setTimeout(callback, time, ...args)
  }
  const updateSavedNotes = editor => {
    notesMutation.mutate(editor.getJSON())
  }
  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({ placeholder: 'Enter any notes for the sprint.' })
    ],
    onUpdate({ editor }) {
      debounce(updateSavedNotes, 3000, editor)
    }
  })
  useEffect(() => {
    if (editor && note) {
      editor.commands.setContent(note.note)
    }
  }, [editor, note])

  if (isLoading || !editor) {
    return <Loading title={'Loading Sprint Notes'} />
  }

  if (error) {
    return (
      <Error title={'Error Loading Sprint Notes'} message={error.message} />
    )
  }

  return (
    <div className='rounded-lg p-4 pb-7 shadow'>
      <EditorContent editor={editor} className='notes-editor' />
    </div>
  )
}

const SprintAnalysis = () => {
  const { projKey, boardId, sprintId } = useParams()
  const analysisApiUri = useApi().analysis
  const analysisQueryFn = async ({ queryKey }) => {
    const res = await fetch(`${analysisApiUri}${queryKey[0]}`)
    const data = await res.json()
    return data
  }
  const {
    isLoadingSprint,
    errorSprint,
    data: sprint
  } = useQuery({
    queryKey: [`/sprints/${sprintId}`],
    staleTime: 600000
  })
  const {
    isLoading,
    error,
    data: analysis
  } = useQuery({
    queryKey: [`/analysis/sprints/${sprintId}`],
    staleTime: 600000,
    enabled: !!sprint,
    queryFn: analysisQueryFn
  })

  if (isLoading) {
    return <Loading message={`Loading sprint ${sprintId} analysis.`} />
  }

  if (error) {
    return (
      <Error title={'Error Loading Sprint Analysis'} message={error.message} />
    )
  }

  const headerConfig = {
    pageTitle: sprint.name,
    backLink: {
      label: 'Back',
      to: '/projects'
    },
    breadcrumb: [
      {
        label: 'Projects',
        to: '/projects'
      },
      {
        label: 'Project Details',
        to: `/projects/${projKey}`
      },
      {
        label: `Sprint ${sprintId} Analysis`
      }
    ],
    rightComponent: (
      <>
        {sprint.state != 'active' && (
          <p className='mt-2 text-sm text-gray-500'>
            {new Date(sprint.startDate).toLocaleDateString('en-US', {
              weekday: 'short',
              day: 'numeric',
              month: 'short'
            })}{' '}
            to{' '}
            {new Date(sprint.endDate).toLocaleDateString('en-US', {
              weekday: 'short',
              day: 'numeric',
              month: 'short'
            })}
          </p>
        )}

        {sprint.state == 'active' && (
          <p className='mt-2 text-sm text-green-600'>
            Active, started on{' '}
            {new Date(sprint.startDate).toLocaleDateString('en-US', {
              weekday: 'short',
              day: 'numeric',
              month: 'short'
            })}
          </p>
        )}
      </>
    )
  }

  return (
    <>
      {headerConfig && <PageHeader config={headerConfig} />}
      <main className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
        <div className='mb-4 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3'>
          <div className='col-span-1'>
            <Header3 title={'Summary'} />
            <div className='gap-4 pb-4 sm:grid'>
              <DeliverySummary analysis={analysis} sprint={sprint} />
              <SprintProgressCard analysis={analysis} sprint={sprint} />
            </div>
          </div>
          <div className='col-span-2 flex flex-col'>
            <Header3 title={'Notes'} />
            <Notes sprintId={sprintId} />
          </div>
        </div>
        <div>
          <Header3 title={'Changes'} />
          <Changes changes={analysis.changes} />
        </div>
        <div>
          <Header3 title={'By User'} />
          <div className='min-h-max rounded-lg'>
            <Users users={sprint.users} analysis={analysis.byUser} />
          </div>
        </div>
      </main>
    </>
  )
}

export default SprintAnalysis
