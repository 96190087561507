import React from 'react'
import { Routes, Route } from 'react-router-dom'
import ProjectDetails from '../Pages/Projects/ProjectDetails'
import ProjectUserDetails from '../Pages/Projects/ProjectUserDetail'
import SprintDetails from '../Pages/Projects/SprintDetails'
import SprintAnalysis from '../Pages/Projects/SprintAnalysis'
import SprintUserAnalysis from '../Pages/Projects/SprintUserAnalysis'
import Projects from '../Pages/Projects/Projects'

export default function ProjectsRoutes() {
  return (
    <Routes>
      <Route index element={<Projects />} />
      <Route path=':projKey' element={<ProjectDetails />} />
      <Route
        path=':projKey/boards/:boardId/sprints/:sprintId/analysis/user/:userId'
        element={<SprintUserAnalysis />}
      />
      <Route
        path=':projKey/boards/:boardId/sprints/:sprintId/analysis'
        element={<SprintAnalysis />}
      />
      <Route
        path=':projKey/boards/:boardId/sprints/:sprintId'
        element={<SprintDetails />}
      />
      <Route path=':projKey/users/report/:userId' element={<ProjectUserDetails />} />
    </Routes>
  )
}
