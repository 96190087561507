import { useQuery } from '@tanstack/react-query'
import { Loading, Error } from '../../Elements//Indicators'
import { useParams } from 'react-router-dom'
import { PageHeader } from '../../Elements//PageHeader'
import { Header3 } from '../../Elements//Type'
import {
  DeliverySummary,
  SprintProgressCard,
  Changes
} from '../../Elements//SprintBlocks'
import { IssueCard } from '../../Elements//JirraIssue'
import { useState } from 'react'
import { ListBoxWithCheck } from '../../Elements//Controls'
import clsx from 'clsx'
import { Switch } from '@headlessui/react'
import { useApi } from '../../AppContext'

const SprintUserAnalysis = () => {
  const { projKey, boardId, sprintId, userId } = useParams()
  const analysisApiUri = useApi().analysis
  const analysisQueryFn = async ({ queryKey }) => {
    const res = await fetch(`${analysisApiUri}${queryKey[0]}`)
    const data = await res.json()
    return data
  }
  const {
    isLoadingSprint,
    errorSprint,
    data: sprint
  } = useQuery({
    queryKey: [`/sprints/${sprintId}`],
    staleTime: 600000
  })
  const {
    isLoading,
    error,
    data: analysis
  } = useQuery({
    queryKey: [`/analysis/sprints/${sprintId}`],
    staleTime: 600000,
    enabled: !!sprint,
    queryFn: analysisQueryFn
  })
  const [selectedType, setSelectedType] = useState({ id: 0, name: 'All Types' })
  const [selectedEpic, setSelectedEpic] = useState({ id: 0, name: 'All Epics' })
  const [selectedStatus, setSelectedStatus] = useState({
    id: 0,
    name: 'All Statuses'
  })
  const [showStartingOnly, setShowStartingOnly] = useState(false)

  if (isLoading) {
    return <Loading message={`Loading sprint ${sprintId} analysis.`} />
  }

  if (error) {
    return (
      <Error title={'Error Loading Sprint Analysis'} message={error.message} />
    )
  }

  const userAnalysis = analysis.byUser.find(user => user.id == userId)
  const user = sprint.users.find(user => user.id == userId)

  const headerConfig = {
    pageTitle: `${user.name} in ${sprint.name}`,
    backLink: {
      label: 'Back',
      to: '/projects'
    },
    breadcrumb: [
      {
        label: 'Projects',
        to: '/projects'
      },
      {
        label: 'Project Details',
        to: `/projects/${projKey}`
      },
      {
        label: `Sprint ${sprint.id} Analysis`,
        to: `/projects/${projKey}/boards/${boardId}/sprints/${sprintId}/analysis`
      },
      {
        label: 'User Analysis'
      }
    ],
    rightComponent: (
      <img className='h-10 w-10 rounded-full' src={user.avatar} alt='' />
    )
  }

  const issueTypes = [
    {
      id: 0,
      name: 'All Types'
    }
  ]
  issueTypes.push(
    ...userAnalysis.start.reduce((types, type) => {
      if (type.count) {
        types.push({
          id: types.length + 1,
          name: type.type
        })
      }
      return types
    }, [])
  )

  const allStartIssues = userAnalysis.start
    .reduce((issues, type) => {
      issues.push(type.issues)
      return issues
    }, [])
    .flat(1)

  let allEndIssues = userAnalysis.end
    .reduce((issues, type) => {
      issues.push(type.issues)
      return issues
    }, [])
    .flat(1)

  const unresolvedIssues = userAnalysis.unresolved
    .reduce((issues, type) => {
      issues.push(type.issues)
      return issues
    }, [])
    .flat(1)

  //copy isPreviouslyReleased from unresolved issues to end issues so that it can be displayed
  allEndIssues = allEndIssues.map(issue => {
    const unresolvedIssue = unresolvedIssues.find(unresolved => Number(unresolved.id) === issue.id)
    if (unresolvedIssue) {
      issue.isPreviouslyReleased = unresolvedIssue.isPreviouslyReleased
    }
    return issue
  })

  const allIssues = []
  allIssues.push(...allEndIssues)

  for (const issue of allStartIssues) {
    if (!allIssues.find(obj => obj.id == issue.id)) {
      allIssues.push(issue)
    }
  }

  for (const issue of allIssues) {
    if (allStartIssues.find(obj => obj.id == issue.id)) {
      issue.startedWith = true
    } else {
      issue.startedWith = false
    }
  }

  const allEpics = allIssues.reduce((epics, issue) => {
    if (issue.epic) {
      epics.push({
        id: issue.epic.id,
        name: issue.epic.name
      })
    }
    return epics
  }, [])

  const uniqEpics = [
    {
      id: 0,
      name: 'All Epics'
    }
  ]

  const uniqStatuses = [
    {
      id: 0,
      name: 'All Statuses'
    },
    {
      id: 1,
      name: 'Done'
    },
    {
      id: 2,
      name: 'Not Done'
    }
  ]

  uniqEpics.push(...new Map(allEpics.map(item => [item['id'], item])).values())

  const noEpicIssueCount = allStartIssues.filter(
    issue => issue.epic == undefined
  ).length

  if (noEpicIssueCount) {
    uniqEpics.push({
      id: -1,
      name: 'No Epic'
    })
  }

  uniqEpics.push()

  const userReportLink = `/projects/${projKey}/users/report/${userId}`

  let displayedIssues = structuredClone(allIssues)

  displayedIssues.forEach(issue => {
    const endIssue = allEndIssues.find(e => e.id == issue.id)
    if (endIssue) {
      issue.resolution = endIssue.resolution
        ? endIssue.resolution
        : issue.resolution
      issue.summary = endIssue.summary ? endIssue.summary : issue.summary
      issue.estimate = endIssue.estimate ? endIssue.estimate : issue.estimate
      issue.type = endIssue.type ? endIssue.type : issue.type
    }
  })
  displayedIssues.sort((a, b) => {
    if (a.epic == undefined) {
      return 1
    }

    if (b.epic == undefined) {
      return -1
    }

    if (a.epic.id === b.epic.id) {
      return 0
    }

    return a.epic.id < b.epic.id ? -1 : 1
  })

  if (selectedType && selectedType.id != 0) {
    displayedIssues = displayedIssues.filter(
      issue => issue.type == selectedType.name
    )
  }

  if (selectedEpic && selectedEpic.id != 0) {
    if (selectedEpic.id < 0) {
      displayedIssues = displayedIssues.filter(issue => issue.epic == undefined)
    } else {
      displayedIssues = displayedIssues.filter(issue =>
        issue.epic ? (issue.epic.id == selectedEpic.id ? true : false) : false
      )
    }
  }

  if (showStartingOnly) {
    displayedIssues = displayedIssues.filter(issue => issue.startedWith == true)
  }

  if (selectedStatus && selectedStatus.id != 0) {
    //1 = done, 2 = not done
    const isDone = selectedStatus.id === 1
    displayedIssues = displayedIssues.filter(issue =>
      issue.status ? (issue.status === 'Done' || issue.status === 'Ready for Release') === isDone : false
    )
  }

  let removedIssues = []
  if (analysis.changes && analysis.changes.removedIssues && analysis.changes.removedIssues.length) {
    removedIssues = analysis.changes.removedIssues
    removedIssues = removedIssues.filter(issue => issue.owner.id == userId)
  }
  return (
    <>
      {headerConfig && <PageHeader config={headerConfig} />}
      <main className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
        <div className='gap-4 pb-4 sm:grid sm:grid-cols-2'>
          <DeliverySummary
            analysis={userAnalysis}
            link={userReportLink}
            sprint={sprint}
            userId={userId}
          />
          <SprintProgressCard analysis={userAnalysis} sprint={sprint} />
        </div>
        <div>
          <Header3 title={'Changes'} />
          <Changes changes={userAnalysis.changes} sprintId={sprintId} />
        </div>

        <div>
          <div className='flex items-center'>
            <Header3 title={'Tickets'} />
            <Switch.Group as='div' className='ml-4 mb-4 flex items-center'>
              <Switch
                checked={showStartingOnly}
                onChange={setShowStartingOnly}
                className={clsx(
                  showStartingOnly ? 'bg-green-600' : 'bg-gray-200',
                  'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2'
                )}
              >
                <span
                  aria-hidden='true'
                  className={clsx(
                    showStartingOnly ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                  )}
                />
              </Switch>
              <Switch.Label as='span' className='ml-3 text-sm'>
                <span className='font-medium text-gray-900'>
                  Show Starting Issues Only
                </span>
              </Switch.Label>
            </Switch.Group>
          </div>

          <div className='mb-4 grid grid-cols-3 gap-4 md:grid-cols-4'>
            <div className='col-span-2'>
              <ListBoxWithCheck
                values={uniqEpics}
                selected={selectedEpic}
                setSelected={setSelectedEpic}
                label={'Epic'}
              />
            </div>
            <div className='col-span-1'>
              <ListBoxWithCheck
                values={issueTypes}
                selected={selectedType}
                setSelected={setSelectedType}
                label={'Issue Type'}
              />
            </div>
            <div className='col-span-1'>
              <ListBoxWithCheck
                values={uniqStatuses}
                selected={selectedStatus}
                setSelected={setSelectedStatus}
                label={'Issue Status'}
              />
            </div>
          </div>
          <div className='grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'>
            {displayedIssues.map(issue => (
              <IssueCard issue={issue} key={issue.id} sprintId={sprintId} removed={removedIssues.some(removedIssue => removedIssue.id == issue.id)} />
            ))}
          </div>
        </div>
      </main>
    </>
  )
}

export default SprintUserAnalysis
