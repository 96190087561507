import { useEffect, useState, useReducer } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Loading, Error, LoadingSmall, LoadingSmallCard } from './Indicators'
import { Line, Doughnut } from 'react-chartjs-2'
import { Chart } from 'chart.js/auto'
import { Header1, Header3, Header4 } from './Type'
import { HorizontalCardWithIcon, SingleStatCard } from './Cards'
import { ReportAnalyzer } from '../../utils/ReportAnalyzer'
import {
  FaceFrownIcon,
  FaceSmileIcon,
  RocketLaunchIcon,
  ClockIcon,
  CheckCircleIcon,
  QuestionMarkCircleIcon
} from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { RadioGroup, Switch } from '@headlessui/react'
import { useParams } from 'react-router-dom'
import { useApi } from '../AppContext'

export const UserStatsChart = () => {
  const [chartData, setChartData] = useState(null)
  const {
    isLoading,
    error,
    data: report
  } = useQuery({
    queryKey: [`/projects/CN2/users/report?startDate=2022-06-01`],
    staleTime: 600000
  })

  const chartOptions = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: 'User Productivity'
      }
    },
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left'
      }
    }
  }

  const getChartDataSets = (statsBySprint, stat) => {
    const sets = []
    let id = 1
    for (sprintStats of statsBySprint) {
      sets.push({
        id: id++,
        label: sprintStats.name,
        data: sprintStats[stat],
        type: 'line'
      })
    }
    return sets
  }

  useEffect(() => {
    if (report) {
      const analyzer = new ReportAnalyzer(report, 'user')
      const data = analyzer.getByUser()
      const sprintNames = data.map(sprint => sprint.name)
      const users = report.users

      const statsBySprint = users.map(user => {
        const userStats = data.flatMap(sprint =>
          sprint.userStats.filter(stat => stat.id === user.id)
        )
        return {
          name: user.name,
          id: user.id,
          resolvedPoints: userStats.flatMap(stat => stat.resolvedPoints),
          unresolvedPoints: userStats.flatMap(stat => stat.unresolvedPoints),
          startPoints: userStats.flatMap(stat => stat.startPoints),
          resolvedBugs: userStats.flatMap(stat => stat.resolvedBugs),
          startBugs: userStats.flatMap(stat => stat.startBugs)
        }
      })

      setChartData({
        labels: sprintNames,
        datasets: getChartDataSets(statsBySprint, 'resolvedPoints')
      })
    }
  }, [report])

  if (isLoading) {
    return <Loading message={`Loading user sprint reports.`} />
  }

  if (error) {
    return (
      <Error
        title={'Error loeaing user sprint reports.'}
        message={error.message}
      />
    )
  }

  if (chartData) {
    return (
      <Line
        className='p-2 sm:p-4 md:p-6 lg:p-8'
        datasetIdKey='statsChart'
        data={chartData}
        options={chartOptions}
      />
    )
  }

  return <h2>User Stats Chart</h2>
}

const getQueryFn = (queryKey, uri) => {
  return async ({ queryKey }) => {
    const res = await fetch(`${uri}${queryKey[0]}`)
    const data = await res.json()
    return data
  }
}

const getStatsUri = (boardId, userId = null) => {
  return userId ? `/boards/${boardId}/stats/user/${userId}` : `/boards/${boardId}/stats/sprints`
}

export const SprintStatsChart = ({
  boardId,
  title,
  options,
  userId = null
}) => {
  const analysisApiUri = useApi().analysis
  const uri = getStatsUri(boardId, userId)
  const [chartData, setChartData] = useState(null)
  const {
    isLoading,
    error,
    data: stats
  } = useQuery({
    queryKey: [uri],
    staleTime: 600000,
    queryFn: getQueryFn(uri, analysisApiUri)
  })
  const defaultOptions = {
    sprints: 4,
    showBugs: true,
    showAverageDelivered: false,
    showTotalAverageDelivered: true,
    showPoints: true
  }

  const chartOptions = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: `Sprint Productivity of ${title}`
      },
      legend: {
        display: false
      }
    },
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        stacked: true,
        beginAtZero: true
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        grid: {
          drawOnChartArea: false
        },
        beginAtZero: true,
        max: 100
      },
      x: {
        display: false
      }
    }
  }

  useEffect(() => {
    if (stats) {
      const displayOptions = Object.assign({}, defaultOptions, options)
      const chartDataSets = []

      if (stats.length) {
        const latestStats = stats.slice(0, displayOptions.sprints).reverse()
        const sprintNames = latestStats
          .map(sprint => sprint.name)
        const resolvedPoints = latestStats
          .map(sprint => sprint.stats['resolvedPoints'])
        const startPoints = latestStats
          .map(sprint => sprint.stats['startPoints'])
        const resolvedBugs = latestStats
          .map(sprint => sprint.stats['resolvedBugs'])
        const startBugs = latestStats
          .map(sprint => sprint.stats['startBugs'])
        const deliveredPercent = latestStats
          .map(sprint => {
            return Math.floor(
              (parseInt(sprint.stats['resolvedPoints']) /
                parseInt(sprint.stats['startPoints'])) *
              100
            )
          })

        const averageDeliveredPercent = Math.floor(
          deliveredPercent.reduce((a, b) => a + b) / deliveredPercent.length
        )

        if (displayOptions.showTotalAverageDelivered) {
          chartDataSets.push({
            label: 'Average Delivered',
            data: Array(deliveredPercent.length).fill(averageDeliveredPercent),
            type: 'line',
            backgroundColor: '#005489',
            borderColor: '#005489',
            borderDash: [],
            pointStyle: false,
            yAxisID: 'y1'
          })
        }

        if (displayOptions.showAverageDelivered) {
          chartDataSets.push({
            label: 'Delivered Percent',
            data: deliveredPercent,
            type: 'line',
            backgroundColor: '#fe9942',
            borderColor: '#fe9942',
            borderDash: [3, 5],
            pointStyle: false,
            tension: 0.4,
            yAxisID: 'y1'
          })
        }

        if (displayOptions.showPoints) {
          chartDataSets.push(
            {
              label: 'Planned Points',
              data: startPoints,
              backgroundColor: '#CCCCCC',
              type: 'bar',
              yAxisID: 'y',
              stack: 'Stack 0'
            },
            {
              label: 'Delivered Points',
              data: resolvedPoints,
              backgroundColor: '#9fc45d',
              type: 'bar',
              yAxisID: 'y',
              stack: 'Stack 1'
            }
          )
        }

        if (displayOptions.showBugs) {
          chartDataSets.push(
            {
              label: 'Starting Bugs',
              data: startBugs,
              backgroundColor: '#AAAAAA',
              type: 'bar',
              yAxisID: 'y',
              stack: 'Stack 0'
            },
            {
              label: 'Fixed Bugs',
              data: resolvedBugs,
              backgroundColor: '#d94d57',
              type: 'bar',
              yAxisID: 'y',
              stack: 'Stack 1'
            }
          )
        }

        setChartData({
          labels: sprintNames,
          datasets: chartDataSets
        })
      } else {
        setChartData(null)
      }
    }
  }, [stats, options, userId])

  if (isLoading) {
    return <Loading message={`Loading sprints reports.`} />
  }

  if (error) {
    return (
      <Error title={'Error loading sprints reports.'} message={error.message} />
    )
  }

  if (chartData) {
    return (
      <Line datasetIdKey='statsChart' data={chartData} options={chartOptions} />
    )
  } else {
    return <p>No data available for this user.</p>
  }
}

const ProductivityAnalysis = ({ title, boardId, userId = null }) => {
  const initialOptions = {
    sprints: 4,
    showBugs: false,
    showAverageDelivered: false,
    showTotalAverageDelivered: false,
    showPoints: true
  }
  const [options, setOptions] = useReducer(
    (state, updates) => ({ ...state, ...updates }),
    initialOptions
  )
  const setNumberOfSprints = num => {
    setOptions({ sprints: num })
  }
  const setShowAverages = show => {
    setOptions({
      showTotalAverageDelivered: show,
      showAverageDelivered: show
    })
  }
  const setShowBugs = show => {
    setOptions({
      showBugs: show
    })
  }

  const numberOptions = [
    {
      value: 4,
      label: '2 Months',
      subLabel: 'Last 4 Sprints'
    },
    {
      value: 8,
      label: '4 Months',
      subLabel: 'Last 8 Sprints'
    },
    {
      value: 16,
      label: '8 Months',
      subLabel: 'Last 16 Sprints'
    }
  ]

  return (
    <div className='mb-12'>
      <Header3 title={title} />
      <div className='mb-4 grid grid-cols-1 border-t border-b border-slate-100 bg-slate-50 p-4 md:grid-cols-8'>
        <RadioGroup
          value={options.sprints}
          onChange={setNumberOfSprints}
          className='md:col-span-5'
        >
          <div className='flex space-x-2'>
            {numberOptions.map(number => (
              <RadioGroup.Option
                key={number.label}
                value={number.value}
                className={({ active, checked }) =>
                  clsx(
                    'relative flex grow cursor-pointer rounded-lg  px-5 py-2 shadow-sm focus:outline-none',
                    !checked && 'bg-white',
                    checked && 'bg-sky-900 bg-opacity-75 text-white',
                    active &&
                    'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300'
                  )
                }
              >
                {({ active, checked }) => (
                  <>
                    <div className='flex w-full items-center justify-between'>
                      <div className='mr-4 hidden shrink-0 lg:inline-flex'>
                        {checked ? (
                          <CheckCircleIcon className='h-6 w-6 text-white' />
                        ) : (
                          <ClockIcon className='h-6 w-6 text-slate-200' />
                        )}
                      </div>

                      <div className='flex grow items-center'>
                        <div className='text-sm'>
                          <RadioGroup.Label
                            as='p'
                            className={`font-medium  ${checked ? 'text-white' : 'text-gray-900'
                              }`}
                          >
                            {number.label}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as='span'
                            className={`inline ${checked ? 'text-sky-100' : 'text-gray-500'
                              }`}
                          >
                            <span>{number.subLabel}</span>
                          </RadioGroup.Description>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
        <div className='mt-4 md:col-span-3 md:ml-8 md:mt-0'>
          <div className='grid grid-cols-2 gap-2 md:grid-cols-1'>
            <Switch.Group>
              <div className='flex'>
                <Switch
                  checked={options.showAverageDelivered}
                  onChange={setShowAverages}
                  className={`${options.showAverageDelivered ? 'bg-blue-600' : 'bg-gray-200'
                    } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                >
                  <span
                    className={`${options.showAverageDelivered
                      ? 'translate-x-6'
                      : 'translate-x-1'
                      } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                  />
                </Switch>
                <Switch.Label className='ml-4'>Show Delivered %</Switch.Label>
              </div>
            </Switch.Group>

            <Switch.Group>
              <div className='flex'>
                <Switch
                  checked={options.showBugs}
                  onChange={setShowBugs}
                  className={`${options.showBugs ? 'bg-blue-600' : 'bg-gray-200'
                    } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                >
                  <span
                    className={`${options.showBugs ? 'translate-x-6' : 'translate-x-1'
                      } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                  />
                </Switch>
                <Switch.Label className='ml-4'>Show Bugs</Switch.Label>
              </div>
            </Switch.Group>
          </div>
        </div>
      </div>
      <div className='mb-4 grid grid-cols-1 gap-4 lg:grid-cols-3'>
        <div className='lg:col-span-2'>
          <div className='mx-auto h-auto rounded-lg bg-white px-4 py-5 shadow sm:p-6'>
            <SprintStatsChart
              boardId={boardId}
              title={title}
              options={options}
              userId={userId}
            />
          </div>
        </div>
        {/* right side */}
        <div className='grid grid-cols-2 gap-4 lg:col-span-1 lg:grid-cols-1'>
          <dl className='grid grid-cols-2 gap-4'>
            <div className='col-span-2'>
              <AverageDelivered
                boardId={boardId}
                sprints={options.sprints}
                userId={userId}
              />
            </div>
            <div className='col-span-1'>
              <AverageByProperty
                boardId={boardId}
                sprints={options.sprints}
                userId={userId}
                property={'resolvedPoints'}
                label={'Points / Sprint'}
              />
            </div>
            <div className='col-span-1'>
              <AverageByProperty
                boardId={boardId}
                sprints={options.sprints}
                userId={userId}
                property={'resolvedBugs'}
                label={'Bugs / Sprint'}
              />
            </div>
          </dl>
          <div className='rounded-lg bg-white py-6 px-8 shadow'>
            <div className='mb-2 truncate text-center text-sm font-medium text-gray-500'>
              Bugs vs. Points
            </div>
            <div className='relative mx-auto h-36 w-36'>
              <BugsVsPointsChart
                boardId={boardId}
                sprints={options.sprints}
                userId={userId}
              />
            </div>
          </div>
        </div>
        {/* end right side */}
      </div>
    </div>
  )
}

export const UserAnalysis = ({ user, boardId }) => {

  return (
    <ProductivityAnalysis
      title={`Productivity Report for ${user.name}`}
      boardId={boardId}
      userId={user.id}
    />
  )
}

export const TeamAnalysis = () => {
  const { boardId } = useParams()
  const {
    isLoading,
    error,
    data: projects
  } = useQuery({
    queryKey: ['/projects'],
    staleTime: 600000
  })

  const getBoards = projects => {
    return projects.map(project => {
      for (const board of project.boards) {
        return {
          projectName: project.name,
          projectKey: project.key,
          projectId: project.id,
          boardId: board.id,
          boardName: board.name
        }
      }
    })
  }

  if (isLoading) {
    return <Loading message={'Loading projects.'} />
  }

  if (error) {
    return <Error message={error.message} />
  }

  if (projects) {
    const boards = getBoards(projects)
    const board = boards.find(board => board.boardId == boardId)

    return (
      <ProductivityAnalysis
        title={board.projectName}
        boardId={boardId}
      />
    )
  }

  return <Header1>Empty State</Header1>
}

const calculateAverageDelivered = (stats) => {
  let total = 0

  stats.forEach(sprint => {
    const deliveredPercent = sprint.stats.resolvedPoints / sprint.stats.startPoints
    total += deliveredPercent
  })

  const average = total / stats.length * 100

  return parseFloat(average.toFixed(1))
}
function calculateAverageByProperty(stats, property) {
  let total = 0

  stats.forEach(sprint => {
    total += sprint.stats[property]
  })

  const average = total / stats.length

  return parseFloat(average.toFixed(1))
}

const AverageByProperty = ({
  property,
  boardId,
  label,
  sprints,
  userId = null
}) => {
  const analysisApiUri = useApi().analysis
  const uri = getStatsUri(boardId, userId)
  const {
    isLoading,
    error,
    data: stats
  } = useQuery({
    queryKey: [uri],
    staleTime: 600000,
    queryFn: getQueryFn(uri, analysisApiUri)
  })

  const [average, setAverage] = useState(null)

  useEffect(() => {
    if (stats) {
      const latestStats = stats.slice(0, sprints)
      setAverage(calculateAverageByProperty(latestStats, property))
    }
  }, [stats, sprints, userId])

  if (isLoading) {
    return <LoadingSmallCard label={'Loading...'} />
  }

  if (average) {
    return <SingleStatCard stat={average} label={label} />
  } else {
    return null
  }
}

const AverageDelivered = ({
  sprints,
  boardId,
  userId = null
}) => {
  const analysisApiUri = useApi().analysis
  const uri = getStatsUri(boardId, userId)
  const {
    isLoading,
    error,
    data: stats
  } = useQuery({
    queryKey: [uri],
    staleTime: 600000,
    queryFn: getQueryFn(uri, analysisApiUri)
  })

  const [average, setAverage] = useState(null)

  let deliveredColor = 'bg-red-500'
  let icon = FaceFrownIcon

  useEffect(() => {
    if (stats) {
      const latestStats = stats.slice(0, sprints)
      setAverage(calculateAverageDelivered(latestStats))
    }
  }, [stats, sprints, userId])

  if (isLoading) {
    return <LoadingSmallCard label='Loading...' />
  }

  if (error) {
    return (
      <Error title={'Error loading average.'} message={error.message} />
    )
  }

  if (average) {
    if (average > 99) {
      deliveredColor = 'bg-green-500'
      icon = RocketLaunchIcon
    } else if (average > 85) {
      deliveredColor = 'bg-yellow-500'
      icon = FaceSmileIcon
    } else if (average > 50) {
      deliveredColor = 'bg-orange-500'
      icon = QuestionMarkCircleIcon
    }

    return (
      <HorizontalCardWithIcon
        title={`${average}%`}
        subtitle={'Average Delivered'}
        icon={icon}
        bgcolor={deliveredColor}
        key={`average-delivered-${userId}`}
      />
    )
  } else {
    return null
  }
}

export const BugsVsPointsChart = ({
  sprints,
  boardId,
  userId = null
}) => {
  const analysisApiUri = useApi().analysis
  const uri = getStatsUri(boardId, userId)
  const [chartData, setChartData] = useState(null)
  const {
    isLoading,
    error,
    data: stats
  } = useQuery({
    queryKey: [uri],
    staleTime: 600000,
    queryFn: getQueryFn(uri, analysisApiUri)
  })

  const chartOptions = {
    maintainAspectRatio: true,
    plugins: {
      title: {
        display: false
      },
      legend: {
        display: false
      }
    }
  }

  const calculateTotals = (stats) => {
    let totalBugs = 0
    let totalPoints = 0

    stats.forEach(sprint => {
      totalBugs += sprint.stats.resolvedBugs
      totalPoints += sprint.stats.resolvedPoints
    })

    return { totalBugs, totalPoints }

  }

  useEffect(() => {
    if (stats) {
      const latestStats = stats.slice(0, sprints)
      const { totalBugs: bugCount, totalPoints: pointsCount } = calculateTotals(latestStats)
      if (bugCount == 0 && pointsCount == 0) {
        setChartData(null)
      } else {
        setChartData({
          labels: ['Bugs', 'Points'],
          datasets: [
            {
              data: [bugCount, pointsCount],
              backgroundColor: ['rgb(255, 0, 0)', 'rgb(2, 132, 199)']
            }
          ]
        })
      }
    }
  }, [stats, sprints, userId])

  if (isLoading) {
    return <LoadingSmall />
  }

  if (error) {
    return (
      <Error title={'Error loading bugs vs points.'} message={error.message} />
    )
  }

  if (chartData) {
    return (
      <Doughnut data={chartData} options={chartOptions} className='mx-auto' />
    )
  }

  return null
}
