import { useParams } from 'react-router'
import { useQuery } from '@tanstack/react-query'
import { Loading, Error } from '../../Elements//Indicators'
import { PageHeader } from '../../Elements/PageHeader'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import UserReport from '../Users/UserReport'

const ProjectUserDetails = () => {
  const { projKey, userId } = useParams()
  const [user, setUser] = useState(null)
  const navigate = useNavigate()
  const {
    isLoading,
    error,
    data: project
  } = useQuery({
    queryKey: [`/projects/${projKey}`],
    staleTime: 600000
  })

  useEffect(() => {
    if (project) {
      setUser(project.users.find(user => user.id == userId))
    }
  }, [project])

  const onChangeUser = userId => {
    setUser(project.users.find(user => user.id == userId))
    navigate(`/projects/${projKey}/users/report/${userId}`)
  }

  if (isLoading) {
    return <Loading message={'Loading project.'} />
  }

  if (error) {
    return <Error message={error.message} />
  }

  if (project && user) {
    const headerConfig = {
      pageTitle: `User Report`,
      backLink: {
        label: 'Back',
        to: `/projects/${projKey}`
      },
      breadcrumb: [
        {
          to: '/projects',
          label: 'Projects'
        },
        {
          to: `/projects/${projKey}`,
          label: 'Project Details'
        },
        {
          label: 'User Report'
        }
      ]
      // rightComponent: (
      //   <img className='h-10 w-10 rounded-full' src={user.avatar} alt='' />
      // )
    }

    return (
      <>
        <PageHeader config={headerConfig} />
        <UserReport onChangeUser={onChangeUser} user={user} project={project} />
      </>
    )
  }

  return <h1>Empty State</h1>
}

export default ProjectUserDetails
