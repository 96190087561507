import { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import clsx from 'clsx'
import { Disclosure } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import JiraXLogo from '../../img/JiraXLogo.png'

const items = [
  { key: 1, name: 'Dashboard', href: '/dashboard', current: true },
  { key: 2, name: 'Projects', href: '/projects', current: false },
  { key: 3, name: 'Users', href: '/users', current: false },
  { key: 4, name: 'Leaderboard', href: '/leaderboard', current: false }
]

const DesktopNav = () => {
  const location = useLocation()
  const [selectedKey, setSelectedKey] = useState(null)

  useEffect(() => {
    const path = location.pathname.split('/').filter(Boolean)

    const item = items.find(item => {
      const itemPath = item.href.split('/').filter(Boolean)
      return path[0] === itemPath[0]
    })

    if (item) {
      setSelectedKey(item.key)
    }
  }, [location])

  return (
    <>
      {items.map(item => (
        <Link
          to={item.href}
          key={'desktop-nav-' + item.key}
          className={clsx(
            item.key == selectedKey
              ? 'border-indigo-500 text-gray-900'
              : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
            'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium'
          )}
          aria-current={item.key == selectedKey ? 'page' : undefined}
        >
          {item.name}
        </Link>
      ))}
    </>
  )
}

const MobileNav = ({ close }) => {
  const location = useLocation()
  const [selectedKey, setSelectedKey] = useState(null)

  useEffect(() => {
    const item = items.find(item => location.pathname == item.href)
    if (item) {
      setSelectedKey(item.key)
    }
  }, [location])

  return (
    <Disclosure.Panel className='sm:hidden'>
      <div className='flex flex-col space-y-1 pt-2 pb-3'>
        {items.map(item => (
          <Disclosure.Button
            as={Link}
            to={item.href}
            key={'mobile-nav-' + item.key}
            className={clsx(
              item.key == selectedKey
                ? 'bg-indigo-500 text-white'
                : 'bg-slate-100 text-slate-500',
              'mx-2 mb-2 rounded p-2'
            )}
            aria-current={item.key == selectedKey ? 'page' : undefined}
          >
            {item.name}
          </Disclosure.Button>
        ))}
      </div>
    </Disclosure.Panel>
  )
}

const Header = () => {
  return (
    <Disclosure as='nav' className='border-b border-gray-200 bg-white'>
      {({ open }) => (
        <>
          <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
            <div className='flex h-16 justify-between'>
              <div className='flex'>
                <div className='flex flex-shrink-0 items-center'>
                  <img
                    className='block h-8 w-auto lg:hidden'
                    src={JiraXLogo}
                    alt='Jira X'
                  />
                  <img
                    className='hidden h-8 w-auto lg:block'
                    src={JiraXLogo}
                    alt='Jira X'
                  />
                </div>
                <div className='hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8'>
                  <DesktopNav />
                </div>
              </div>
              <div className='hidden sm:ml-6 sm:flex sm:items-center'>
                {/* <button
                  type="button"
                  className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}
              </div>
              <div className='-mr-2 flex items-center sm:hidden'>
                {/* Mobile menu button */}
                <Disclosure.Button className='inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>
                  <span className='sr-only'>Open main menu</span>
                  {open ? (
                    <XMarkIcon className='block h-6 w-6' aria-hidden='true' />
                  ) : (
                    <Bars3Icon className='block h-6 w-6' aria-hidden='true' />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>
          <MobileNav />
        </>
      )}
    </Disclosure>
  )
}

export default Header
