import { ArrowPathIcon } from '@heroicons/react/20/solid'
import { PSmallFaded } from './Type'

export const LoadingSmall = ({ label }) => {
  return (
    <div className='flex justify-center items-center'>
      <ArrowPathIcon className='h-5 w-5 animate-spin text-sky-600' />
      {label && <PSmallFaded className='ml-4' text={label} />}
    </div>
  )
}

export const LoadingSmallCard = ({ label }) => {
  return (
    <div className='flex justify-center items-center rounded-lg bg-white shadow p-4 text-center flex-col space-y-4'>
      <ArrowPathIcon className='h-5 w-5 animate-spin text-sky-600' />
      {label && <PSmallFaded className='ml-4' text={label} />}
    </div>
  )
}

export const Loading = ({ label, message }) => {
  return (
    <div className='container mx-auto my-14 max-w-xs overflow-hidden rounded-lg bg-white'>
      <div className='px-4 py-5 text-center sm:p-6'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 24 24'
          fill='currentColor'
          className='mx-auto my-3 h-12 w-12 animate-spin'
        >
          <path
            fillRule='evenodd'
            d='M4.755 10.059a7.5 7.5 0 0112.548-3.364l1.903 1.903h-3.183a.75.75 0 100 1.5h4.992a.75.75 0 00.75-.75V4.356a.75.75 0 00-1.5 0v3.18l-1.9-1.9A9 9 0 003.306 9.67a.75.75 0 101.45.388zm15.408 3.352a.75.75 0 00-.919.53 7.5 7.5 0 01-12.548 3.364l-1.902-1.903h3.183a.75.75 0 000-1.5H2.984a.75.75 0 00-.75.75v4.992a.75.75 0 001.5 0v-3.18l1.9 1.9a9 9 0 0015.059-4.035.75.75 0 00-.53-.918z'
            clipRule='evenodd'
          />
        </svg>

        <h2 className='text-xl font-bold'>
          {label ? 'Loading ' + label : 'Loading'}
        </h2>
        {message && <p className='my-2 text-slate-500'>{message}</p>}
      </div>
    </div>
  )
}

export const Error = ({ title, message }) => {
  return (
    <div className='container mx-auto my-14 max-w-lg overflow-hidden rounded-lg bg-rose-800 shadow-md'>
      <div className='px-4 py-5 text-center sm:p-6'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='currentColor'
          className='mx-auto my-3 h-12 w-12 stroke-white'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z'
          />
        </svg>

        <h2 className='text-xl font-bold text-white'>
          {title ? <>{title}</> : 'Unknown Error'}
        </h2>
        {message && <p className='text-slate-70 my-2 text-white'>{message}</p>}
      </div>
    </div>
  )
}
