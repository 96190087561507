import { Outlet } from 'react-router-dom'
import Header from "./Elements/Header"

const Layout = () => {
  return (
    <div className='min-h-full'>
      <Header />
      <div className='py-4'>
        <Outlet />
      </div>
    </div>
  )
}

export default Layout
