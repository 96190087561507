import { IssuesPanel } from './JirraIssue'
import { HorizontalCardWithIcon, ProgressBar } from './Cards'
import { useState } from 'react'
import {
  CheckBadgeIcon,
  BugAntIcon,
  RectangleStackIcon,
  ArrowPathIcon,
  ArrowUpIcon,
  ArrowDownIcon
} from '@heroicons/react/20/solid'
import { Header3, Header2, PSmallFaded, PLarge } from './Type'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { LoadingSmall } from './Indicators'

export const SprintProgressCard = ({ analysis }) => {
  const pointsDeliveredPercent = analysis.resolvedPoints
    ? analysis.resolvedPoints / analysis.startPoints
    : 0
  const bugsDeliveredPercent = analysis.resolvedBugs
    ? analysis.resolvedBugs / analysis.startBugs
    : 0

  let issueCount = 0

  if (analysis.start) {
    for (const issueType of analysis.start) {
      if (issueType.type != 'Bug') {
        issueCount += parseInt(issueType.count)
      }
    }
  } else if (analysis.byUser) {
    for (const user of analysis.byUser) {
      for (const issueType of user.start) {
        if (issueType.type != 'Bug') {
          issueCount += parseInt(issueType.count)
        }
      }
    }
  }

  return (
    <div className='flex flex-col rounded-lg bg-white p-4 text-left shadow'>
      <div>
        {pointsDeliveredPercent > 1 ? (
          <ProgressBar
            percent={pointsDeliveredPercent % 1}
            bgColor={'bg-sky-600'}
            barBgColor={'bg-sky-900'}
            height={3}
            label={`${analysis.resolvedPoints} out of ${analysis.startPoints} Points (${issueCount} tickets)`}
          />
        ) : (
          <ProgressBar
            percent={pointsDeliveredPercent}
            bgColor={'bg-sky-100'}
            barBgColor={'bg-sky-600'}
            height={3}
            label={`${analysis.resolvedPoints} out of ${analysis.startPoints} Points (${issueCount} tickets)`}
          />
        )}
      </div>
      <div>
        {bugsDeliveredPercent > 1 ? (
          <ProgressBar
            percent={bugsDeliveredPercent % 1}
            bgColor={'bg-red-600'}
            barBgColor={'bg-red-900'}
            height={3}
            label={
              analysis.resolvedBugs + ' out of ' + analysis.startBugs + ' Bugs'
            }
          />
        ) : (
          <ProgressBar
            percent={bugsDeliveredPercent}
            bgColor={'bg-red-100'}
            barBgColor={'bg-red-600'}
            height={3}
            label={
              analysis.resolvedBugs + ' out of ' + analysis.startBugs + ' Bugs'
            }
          />
        )}
      </div>
    </div>
  )
}

export const DeliverySummary = ({ analysis, link, sprint, userId }) => {
  const getPercent = (resolvedPoints, startPoints) => resolvedPoints ? resolvedPoints / startPoints : 0
  const getChangeType = (lastPointsDeliveredPercent, deliveredPercent) => lastPointsDeliveredPercent < deliveredPercent ? 'increase' : 'decrease'
  const getPercentChangeString = (changeType, percentChange) => `${changeType == 'increase' ? '+' : '-'} ${Number(percentChange).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0 })}`
  const getChangeIcon = changeType => changeType == 'increase' ? ArrowUpIcon : ArrowDownIcon
  const useSprints = boardId => useQuery({
    queryKey: [`/boards/${boardId}/sprints`],
    staleTime: 600000
  })
  const usePastSprintReports = (sprintId, userId) => {
    if (userId) {
      return useQuery({
        queryKey: [`/sprints/${sprintId}/analysis/past-delivery/${userId}`],
        staleTime: 600000,
        enabled: !!sprints
      })

    } else {
      return useQuery({
        queryKey: [`/sprints/${sprintId}/analysis/past-delivery`],
        staleTime: 600000,
        enabled: !!sprints
      })
    }
  }

  const { board: { projectKey, id: boardId }, id: sprintId } = sprint
  const deliveredPercent = getPercent(analysis.resolvedPoints, analysis.startPoints)
  const { isLoading: isLoadingSprints, error: isErrorSprints, data: sprints } = useSprints(boardId)
  const { isLoading, error, data: previousSprintsDelivery } = usePastSprintReports(sprintId, userId)

  const getPreviousSprintDeliveryAverage = (lastFiveSprints) => {
    let totalPercent = 0
    for (let sprint of lastFiveSprints) {
      if (sprint.resolvedPoints == 0) {
        continue
      } else if (sprint.startPoints == 0) {
        totalPercent += 1
      } else {
        totalPercent += (sprint.resolvedPoints / sprint.startPoints)
      }
    }
    return totalPercent / 5
  }

  let averageLastFourSprints = 0
  let changeType = ''
  let percentDelta = 0
  let percentChange = 0
  let percentChangeString = ''
  let ChangeIcon = ArrowUpIcon

  if (previousSprintsDelivery) {
    if (userId) {
      previousSprintsDelivery.sort((a, b) => b.sprintId - a.sprintId)
      const lastFourSprints = previousSprintsDelivery.slice(0, 4)
      averageLastFourSprints = getPreviousSprintDeliveryAverage(lastFourSprints)
    } else {
      const sprintIds = [...new Set(previousSprintsDelivery.map(item => item.sprintId))]
      sprintIds.sort((a, b) => b - a)
      const lastFourSprintsData = previousSprintsDelivery.filter(item => sprintIds.slice(0, 4).includes(item.sprintId))
      const totalStartPoints = lastFourSprintsData.reduce((total, item) => total + item.startPoints, 0)
      const totalResolvedPoints = lastFourSprintsData.reduce((total, item) => total + item.resolvedPoints, 0)
      averageLastFourSprints = totalResolvedPoints / totalStartPoints
    }
    changeType = getChangeType(averageLastFourSprints, deliveredPercent)
    percentDelta = Math.abs(averageLastFourSprints - deliveredPercent)
    percentChange = percentDelta / averageLastFourSprints
    percentChangeString = getPercentChangeString(changeType, percentChange)
    ChangeIcon = getChangeIcon(changeType)
  }

  const percentString = Number(deliveredPercent).toLocaleString(undefined, {
    style: 'percent',
    minimumFractionDigits: 0
  })
  let commitmentColor = 'bg-red-500'
  if (deliveredPercent > 0.99) {
    commitmentColor = 'bg-green-500'
  } else if (deliveredPercent > 0.85) {
    commitmentColor = 'bg-yellow-500'
  } else if (deliveredPercent > 0.5) {
    commitmentColor = 'bg-orange-500'
  }

  const Content = () => {
    return (
      <div>
        <div>
          <div className={clsx('absolute rounded-md p-3', commitmentColor)}>
            <CheckBadgeIcon className='h-6 w-6 text-white' aria-hidden='true' />
          </div>
          <div className='ml-16'>
            <PSmallFaded text={'Commitment Delivered'} />
          </div>
        </div>
        <div className='ml-16 flex items-center'>
          <PLarge text={percentString} />
          <div className='ml-2'>
            {isLoading && <LoadingSmall />}
            {previousSprintsDelivery && (
              <p
                className={clsx(
                  changeType == 'increase' ? 'text-green-600' : 'text-red-600',
                  'flex items-baseline text-sm font-semibold'
                )}
              >
                <ChangeIcon
                  className={clsx(
                    changeType == 'increase'
                      ? 'text-green-600'
                      : 'text-red-600',
                    'h-5 w-5 flex-shrink-0 self-center'
                  )}
                  aria-hidden='true'
                />
                <span className='sr-only'>
                  {' '}
                  {changeType == 'increase' ? 'Increased' : 'Decreased'} by{' '}
                </span>
                {percentChangeString}
              </p>
            )}
          </div>
        </div>
      </div>
    )
  }

  if (link) {
    return (
      <Link to={link}>
        <div className='relative flex min-h-full items-center overflow-hidden rounded-lg bg-white px-4 py-5 shadow hover:bg-sky-50'>
          <Content />
        </div>
      </Link>
    )
  } else {
    return (
      <div className='relative flex items-center overflow-hidden rounded-lg bg-white px-4 py-5 shadow'>
        <Content />
      </div>
    )
  }
}


export const Changes = ({ changes, sprintId }) => {
  const [isBugsShowing, setIsBugsShowing] = useState(false)
  const [isNonBugsShowing, setIsNonBugsShowing] = useState(false)
  const [isChangedShowing, setIsChangedShowing] = useState(false)
  const addedBugs = changes.addedIssues.filter(issue => issue.type == 'Bug')
  const removedBugs = changes.removedIssues.filter(issue => issue.type == 'Bug')
  const addedIssues = changes.addedIssues.filter(issue => issue.type != 'Bug')
  const removedIssues = changes.removedIssues.filter(
    issue => issue.type != 'Bug'
  )
  const changedNonBugsIssues = changes.changedIssues.filter(
    issue => issue.type != 'Bug'
  )

  const getPointsChangesFromIssues = issues => {
    let sum = 0
    if (issues && issues.length != 0) {
      sum = issues.reduce((net, issue) => {
        const startingEstimate = issue.startEstimate ? issue.startEstimate : 0
        const endEstimate = issue.estimate ? issue.estimate : 0
        return net + (endEstimate - startingEstimate)
      }, 0)
    }
    return sum
  }

  const bugsDelta = changes.bugsAdded - changes.bugsRemoved
  const nonBugsDelta = addedIssues.length - removedIssues.length
  const pointsChanged = getPointsChangesFromIssues(changedNonBugsIssues)
  const changesInfo = [
    {
      title: `${bugsDelta > 0 ? '+' : ''}${bugsDelta} Bugs`,
      subtitle: `${changes.bugsAdded} Added, ${changes.bugsRemoved} Removed`,
      isOpen: isBugsShowing,
      setOpen: setIsBugsShowing,
      icon: BugAntIcon,
      bgcolor: 'bg-red-500',
      categories: [
        {
          name: 'Added',
          issues: addedBugs
        },
        {
          name: 'Removed',
          issues: removedBugs
        }
      ]
    },
    {
      title: `${nonBugsDelta > 0 ? '+' : ''}${nonBugsDelta} Tickets (${nonBugsDelta > 0 ? '+' : ''
        }${changes.pointsAdded - changes.pointsRemoved} Points)`,
      subtitle: `${addedIssues.length} Added, ${removedIssues.length} Removed`,
      isOpen: isNonBugsShowing,
      setOpen: setIsNonBugsShowing,
      icon: RectangleStackIcon,
      bgcolor: 'bg-sky-500',
      categories: [
        {
          name: 'Added',
          issues: addedIssues
        },
        {
          name: 'Removed',
          issues: removedIssues
        }
      ]
    },
    {
      title: `${changedNonBugsIssues.length} Changes (${pointsChanged > 0 ? '+' : ''
        }${pointsChanged} Points)`,
      subtitle: `${pointsChanged > 0
        ? 'Estimates Increased'
        : pointsChanged < 0
          ? 'Estimates Decreased'
          : 'No Change in Estimates'
        }`,
      isOpen: isChangedShowing,
      setOpen: setIsChangedShowing,
      icon: ArrowPathIcon,
      bgcolor: 'bg-orange-500',
      categories: [
        {
          name: 'Updated Tickets',
          issues: changedNonBugsIssues
        }
      ]
    }
  ]

  return (
    <div className='mb-4 min-h-max pb-4'>
      <div
        role='list'
        className='grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-3'
      >
        {changesInfo.map(change => {
          return (
            <HorizontalCardWithIcon
              key={change.title}
              title={change.title}
              subtitle={change.subtitle}
              icon={change.icon}
              bgcolor={change.bgcolor}
              onClick={() => change.setOpen(true)}
            />
          )
        })}
      </div>
      <div>
        {changesInfo.map(change => {
          return (
            <IssuesPanel
              key={`${change.title}-panel`}
              categories={change.categories}
              sprintId={sprintId}
              title={change.title}
              isOpen={change.isOpen}
              setIsOpen={change.setOpen}
            />
          )
        })}
      </div>
    </div>
  )
}
