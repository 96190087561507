import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Users from '../Pages/Users/Users'
import UserUserDetail from '../Pages/Users/UserUserDetail'

export default function ProjectsRoutes() {
  return (
    <Routes>
      <Route index element={<Users />} />
      <Route path=':boardId/:userId' element={<UserUserDetail />} />
    </Routes>
  )
}
