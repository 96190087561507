import { UsersIcon } from '@heroicons/react/20/solid'
import { useQuery } from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import { Loading, Error } from '../../Elements//Indicators'
import { PageHeader } from '../../Elements//PageHeader'

const Projects = () => {
  const {
    isLoading,
    error,
    data: projects
  } = useQuery({
    queryKey: ['/projects'],
    staleTime: 600000
  })

  if (isLoading) {
    return <Loading message={'Loading projects.'} />
  }

  if (error) {
    return <Error message={error.message} />
  }

  projects.sort((a, b) => (a.id > b.id ? -1 : 1))

  return (
    <>
      <PageHeader config={{ pageTitle: 'Projects' }} />
      <main>
        <div className='mx-auto max-w-7xl sm:px-6 lg:px-8'>
          <div className='px-4 pb-8 sm:px-0'>
            <div className='min-h-max'>
              <div className='overflow-hidden bg-white shadow sm:rounded-md'>
                <ul role='list' className='divide-y divide-gray-200'>
                  {projects.map(project => (
                    <li key={project.id}>
                      <Link
                        to={'/projects/' + project.key}
                        className='block hover:bg-gray-50'
                      >
                        <div className='px-4 py-4 sm:px-6'>
                          <div className='flex items-center justify-between'>
                            <p className='text-md truncate font-medium text-indigo-600'>
                              {project.name}
                            </p>
                            <div className='ml-2 flex flex-shrink-0'>
                              <p className='inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800'>
                                {project.key}
                              </p>
                            </div>
                          </div>
                          <div className='mt-2 sm:flex sm:justify-between'>
                            <div className='sm:flex'>
                              <p className='flex items-center text-sm text-gray-500'>
                                <UsersIcon
                                  className='mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400'
                                  aria-hidden='true'
                                />
                                {project.users.length} Members
                              </p>
                              <p className='mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6'>
                                <img
                                  className='mr-2 h-5 w-5 rounded-full'
                                  src={project.lead.avatar}
                                  alt=''
                                />
                                {project.lead.name}
                              </p>
                            </div>
                            <div className='mt-2 flex items-center text-sm text-gray-500 sm:mt-0'>
                              <p>ID: {project.id}</p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Projects
