import { useQuery } from '@tanstack/react-query'
import { Loading, Error } from '../../Elements//Indicators'
import { Header1 } from '../../Elements//Type'
import { useState, useEffect } from 'react'
import { PageHeader } from '../../Elements//PageHeader'
import { useLocation, useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { RadioGroup } from '@headlessui/react'
import clsx from 'clsx'
import { UsersIcon } from '@heroicons/react/20/solid'
import TeamLeaderboard from './TeamLeaderboard'

const getBoards = projects => {
  return projects.map(project => {
    for (const board of project.boards) {
      return {
        projectName: project.name,
        projectKey: project.key,
        projectId: project.id,
        id: board.id,
        name: board.name
      }
    }
  })
}

const getFirstBoard = (projects) => {
  projects.sort((a, b) => (a.id > b.id ? -1 : 1))
  const boards = getBoards(projects)
  const board = boards[boards.length - 1]
  return board
}

const Leaderboard = () => {
  const navigate = useNavigate()
  const { boardId } = useParams()
  const [project, setProject] = useState(null)
  const {
    isLoading,
    error,
    data: projects
  } = useQuery({
    queryKey: ['/projects'],
    staleTime: 600000
  })

  useEffect(() => {
    if (projects && boardId) {
      const foundProject = projects.find(project => project.boards.some(b => b.id === parseInt(boardId)))
      if (foundProject !== project) {
        setProject(foundProject)
      }
    } else if (projects) {
      const firstBoard = getFirstBoard(projects)
      navigate(String(firstBoard.id))
    }
  }, [boardId, projects])

  const changeBoard = boardId => {
    setProject(null)
    navigate(String(boardId))
  }

  if (isLoading) {
    return <Loading message={'Loading projects.'} />
  }

  if (error) {
    return <Error message={error.message} />
  }

  if (projects && boardId) {
    return (
      <>
        <PageHeader config={{ pageTitle: 'Team Leaderboard' }} />
        <main>
          <div className='mx-auto max-w-7xl sm:px-6 lg:px-8'>
            <RadioGroup
              value={boardId}
              onChange={changeBoard}
              className={clsx(
                'grid grid-cols-1 gap-2 px-4 sm:gap-8 sm:px-0',
                'sm:grid-cols-' + getBoards(projects).length
              )}
            >
              {getBoards(projects).map(board => (
                <RadioGroup.Option
                  key={board.projectName}
                  value={board.id.toString()}
                  className={({ active, checked }) =>
                    clsx(
                      checked && 'bg-sky-900 bg-opacity-75 text-white',
                      active &&
                      'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300',
                      'relative col-span-1 flex cursor-pointer rounded-lg px-5 py-2 shadow-sm focus:outline-none'
                    )
                  }
                >
                  {({ active, checked }) => (
                    <>
                      <div className='flex w-full items-center justify-between'>
                        <div className='mr-4 shrink-0 text-white'>
                          {checked ? (
                            <UsersIcon className='text-white-700 h-6 w-6' />
                          ) : (
                            <UsersIcon className='h-6 w-6 text-slate-500' />
                          )}
                        </div>

                        <div className='flex grow items-center'>
                          <div className='text-sm'>
                            <RadioGroup.Label
                              as='p'
                              className={`font-medium  ${checked ? 'text-white' : 'text-gray-900'
                                }`}
                            >
                              {board.projectName}
                            </RadioGroup.Label>
                            <RadioGroup.Description
                              as='span'
                              className={`inline ${checked ? 'text-sky-100' : 'text-gray-500'
                                }`}
                            >
                              <span>{board.name}</span>
                            </RadioGroup.Description>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </RadioGroup>
          </div>
          <div className='mx-auto max-w-7xl sm:px-6 lg:px-8'>
            <div className='px-4 py-8 sm:px-0'>
              {project && <TeamLeaderboard project={project} boardId={boardId} />}
            </div>
          </div>
        </main>
      </>
    )
  }

  return <Header1>Empty State</Header1>
}

export default Leaderboard
