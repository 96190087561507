import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import {
  QueryClient,
  QueryClientProvider,
  useQuery
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import Layout from './Layout'
import { ApiContext } from './AppContext'

import ProjectsRoutes from './Routers/ProjectsRouter'
import DashboardRoutes from './Routers/DashboardRouter'
import UsersRoutes from './Routers/UsersRouter'
import LeaderboardRoutes from './Routers/LeaderboardRouter'

let api
if (process.env.NODE_ENV == 'production') {
  api = {
    main: 'https://serverless-jira-api.courthousenews.workers.dev',
    analysis: 'https://jira-x-analyzer.courthousenews.workers.dev'
  }
} else {
  api = {
    main: 'http://localhost:8787',
    analysis: 'http://localhost:63254'
  }
}

console.log(`${process.env.NODE_ENV} - Main API: ${api.main}, Analysis API: ${api.analysis}`)

const defaultQueryFn = async ({ queryKey }) => {
  const res = await fetch(`${api.main}${queryKey[0]}`)
  const data = await res.json()
  return data
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn
    }
  }
})

export default function App() {
  return (
    <ApiContext.Provider value={api}>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route exact path='/' element={<Navigate to='/dashboard' />} />
            <Route path='dashboard/*' element={<DashboardRoutes />} />
            <Route path='projects/*' element={<ProjectsRoutes />} />
            <Route path='users/*' element={<UsersRoutes />} />
            <Route path='leaderboard/*' element={<LeaderboardRoutes />} />
            <Route path='*' element={<Navigate to='/dashboard' />} />
          </Route>
        </Routes>
        <ReactQueryDevtools initialIsOpen />
      </QueryClientProvider>
    </ApiContext.Provider>
  )
}
