import clsx from 'clsx'

export const Header1 = ({ title }) => {
  return (
    <h1 className='text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight'>
      {title}
    </h1>
  )
}

export const Header2 = ({ title }) => {
  return (
    <h2 className='mb-4 text-xl font-medium leading-7 text-slate-800 sm:truncate sm:text-2xl sm:tracking-tight'>
      {title}
    </h2>
  )
}

export const Header3 = ({ title }) => {
  return (
    <h3 className='mb-4 text-lg font-medium leading-7 text-sky-600 sm:truncate sm:text-xl sm:tracking-tight'>
      {title}
    </h3>
  )
}

export const Header4 = ({ title }) => {
  return <h4 className='text-md mb-2 font-medium text-gray-900'>{title}</h4>
}

export const PLarge = ({ text }) => {
  return <p className='text-2xl font-semibold text-gray-900'>{text}</p>
}

export const PMedium = ({ text }) => {
  return <p className='flex-1 truncate px-4 py-2 text-lg font-medium'>{text}</p>
}

export const PSmallFaded = ({ text }) => {
  return <p className='text-sm text-gray-500'>{text}</p>
}

export const PSmallStrong = ({ text }) => {
  return <p className='text-sm font-bold text-gray-700'>{text}</p>
}

export const Pill = ({ text, color = 'slate' }) => {
  const bgcolor = `bg-${color}-100`
  const textcolor = `text-${color}-800`

  return (
    <span
      className={clsx(
        'rounded-full px-2 py-1 text-xs font-medium',
        bgcolor,
        textcolor
      )}
    >
      {text}
    </span>
  )
}
