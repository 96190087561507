import { RadioGroup } from '@headlessui/react'
import clsx from 'clsx'

const ProjectUserSelector = ({ onChangeUser, project, user }) => {

  const boardId = project.boards[0].id
  const handleChange = (userId) => {
    onChangeUser(userId, boardId)
  }

  project.users.sort((a, b) => (a.id > b.id ? -1 : 1))

  return (
    <div className='mx-auto max-w-7xl pb-6'>
      <RadioGroup
        value={user?.id}
        onChange={handleChange}
        className='grid grid-cols-2 gap-6 md:grid-cols-3 lg:grid-cols-4'
      >
        {project.users.map(user => (
          <RadioGroup.Option
            key={`${boardId}-${user.id}`}
            value={user.id}
            className={({ active, checked }) =>
              clsx(
                checked && 'bg-sky-900 bg-opacity-75 text-white',
                active &&
                'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300',
                'relative col-span-1 flex cursor-pointer rounded-lg px-5 py-2 shadow-sm focus:outline-none hover:bg-slate-100 hover:ring-2 hover:ring-sky-900 hover:ring-offset-2 border-sky-100 border-2',
              )
            }
          >
            {({ active, checked }) => (
              <>
                <div className='flex w-full items-center justify-between'>
                  <div className='mr-4 shrink-0 text-white'>
                    <img
                      className='h-10 w-10 rounded-full'
                      src={user.avatar}
                    />
                  </div>

                  <div className='flex grow items-center'>
                    <div className='text-sm'>
                      <RadioGroup.Label
                        as='p'
                        className={`font-medium  ${checked ? 'text-white' : 'text-gray-900'
                          }`}
                      >
                        {user.name}
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as='span'
                        className={`inline ${checked ? 'text-sky-100' : 'text-gray-500'
                          }`}
                      >
                        <span>{user.role}</span>
                      </RadioGroup.Description>
                    </div>
                  </div>
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </RadioGroup>
    </div>
  )
}

export default ProjectUserSelector
