import React from 'react'

export const ApiContext = React.createContext(null)

export const useApi = () => {
  const context = React.useContext(ApiContext)
  if (context === undefined) {
    throw new Error('API must be used within a ApiContextProvider')
  }
  return context
}
