import clsx from 'clsx'
import { Header4, Pill, PSmallFaded, PSmallStrong } from './Type'
import { Link } from 'react-router-dom'

export const SingleStatCard = ({ stat, label }) => {
  return (
    <div className='overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 lg:py-3'>
      <div className='truncate text-sm font-medium text-gray-500'>{label}</div>
      <div className='mt-1 text-3xl font-semibold tracking-tight text-gray-900'>
        {stat}
      </div>
    </div>
  )
}

export const HorizontalCardWithIcon = ({
  title,
  subtitle,
  icon,
  bgcolor,
  onClick
}) => {
  const CardIcon = icon
  return (
    <div
      onClick={onClick ? onClick : null}
      className={clsx(
        onClick && 'hover:bg-slate-50',
        'col-span-1 flex cursor-pointer rounded-lg bg-white text-left shadow'
      )}
    >
      <div
        className={clsx(
          bgcolor,
          'flex w-24 flex-shrink-0 items-center justify-center rounded-l-md'
        )}
      >
        <CardIcon className='h-12 w-12 text-white' aria-hidden='true' />
      </div>
      <div className='flex flex-1 items-center justify-between truncate rounded-r-md'>
        <div className='overflow-hidden px-4 py-5 pl-8 sm:p-6 lg:py-3'>
          <dt className='truncate text-sm font-medium text-gray-500'>
            {subtitle}
          </dt>
          <dd className='mt-1 text-xl font-semibold tracking-tight text-gray-900'>
            {title}
          </dd>
        </div>
      </div>
    </div>
  )
}

export const ProgressBar = ({
  percent = 0,
  height = 1,
  bgColor = 'bg-slate-50',
  barBgColor = 'bg-slate-500',
  label = null
}) => {
  const width = Number(percent).toLocaleString(undefined, {
    style: 'percent',
    minimumFractionDigits: 0
  })

  return (
    <div className='mb-2'>
      {label && (
        <div className='mb-2'>
          <PSmallFaded text={label} />
        </div>
      )}
      <div className={clsx('w-full', `h-${height}`, bgColor)}>
        <div
          className={clsx(`h-${height}`, barBgColor)}
          style={{ width: width }}
        ></div>
      </div>
    </div>
  )
}

export const UserProgressCard = ({ user, link = null }) => {
  const pointsDeliveredPercent = user.resolvedPoints
    ? user.resolvedPoints / user.startPoints
    : 0
  const bugsDeliveredPercent = user.resolvedBugs
    ? user.resolvedBugs / user.startBugs
    : 0

  let progressColor = 'red'

  if (pointsDeliveredPercent > 0.99) {
    progressColor = 'green'
  } else if (pointsDeliveredPercent > 0.85) {
    progressColor = 'yellow'
  } else if (pointsDeliveredPercent > 0.5) {
    progressColor = 'orange'
  }

  const Content = () => {
    return (
      <div className='relative flex flex-1 flex-col p-8'>
        <div className='absolute top-0 right-0 mt-4 mr-4'>
          <Pill
            color={progressColor}
            textcolor={progressColor}
            text={Number(pointsDeliveredPercent).toLocaleString(undefined, {
              style: 'percent',
              minimumFractionDigits: 0
            })}
          />
          {}
        </div>
        <img
          className='mx-auto h-12 w-12 flex-shrink-0 rounded-full'
          src={user.avatar}
          alt=''
        />
        <Header4 title={user.name} />
        <div className='mt-1'>
          <Pill color={'slate'} text={user.role} />
        </div>

        <div className='mt-4'>
          {pointsDeliveredPercent > 1 ? (
            <ProgressBar
              percent={pointsDeliveredPercent % 1}
              bgColor={'bg-sky-600'}
              barBgColor={'bg-sky-900'}
            />
          ) : (
            <ProgressBar
              percent={pointsDeliveredPercent}
              bgColor={'bg-sky-100'}
              barBgColor={'bg-sky-600'}
            />
          )}
        </div>

        <div className='mt-2'>
          {bugsDeliveredPercent > 1 ? (
            <ProgressBar
              percent={bugsDeliveredPercent % 1}
              bgColor={'bg-red-600'}
              barBgColor={'bg-red-900'}
            />
          ) : (
            <ProgressBar
              percent={bugsDeliveredPercent}
              bgColor={'bg-red-100'}
              barBgColor={'bg-red-600'}
            />
          )}
        </div>

        <div className='mt-4 grid grid-cols-2'>
          {user.resolvedPoints > user.startPoints ? (
            <PSmallStrong
              text={`Points: ${user.resolvedPoints} / ${user.startPoints}`}
            />
          ) : (
            <PSmallFaded
              text={`Points: ${user.resolvedPoints} / ${user.startPoints}`}
            />
          )}
          {user.resolvedBugs > user.startBugs ? (
            <PSmallStrong
              text={`Bugs: ${user.resolvedBugs} / ${user.startBugs}`}
            />
          ) : (
            <PSmallFaded
              text={`Bugs: ${user.resolvedBugs} / ${user.startBugs}`}
            />
          )}
        </div>
      </div>
    )
  }

  if (link) {
    return (
      <Link key={user.id} to={link}>
        <div className='col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow hover:bg-sky-50'>
          <Content />
        </div>
      </Link>
    )
  } else {
    return (
      <div
        key={user.id}
        className='col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow'
      >
        <Content />
      </div>
    )
  }
}
