import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { Loading, Error } from '../../Elements//Indicators'
import { PageHeader } from '../../Elements//PageHeader'
import ProjectUserSelector from '../Projects/ProjectUserSeletor'
import { Header3 } from '../../Elements//Type'

const Users = () => {
  const navigate = useNavigate()
  const {
    isLoading,
    error,
    data: projects
  } = useQuery({
    queryKey: ['/projects'],
    staleTime: 600000
  })

  const onChangeUser = (userId, boardId) => {
    navigate(`/users/${boardId}/${userId}`)
  }

  if (isLoading) {
    return <Loading message={'Loading projects.'} />
  }

  if (error) {
    return <Error message={error.message} />
  }

  if (projects) {
    projects.sort((a, b) => (a.id > b.id ? -1 : 1))

    return (
      <>
        <PageHeader config={{ pageTitle: 'Users' }} key={'UsersList'} />
        <main>
          <div className='mx-auto max-w-7xl sm:px-6 lg:px-8'>
            <div className='px-4 pb-8 sm:px-0'>
              {projects.map(project => (
                <div className='pb-8' key={project.id}>
                  <Header3 title={project.name} />
                  <div className='min-h-max'>
                    <div className='bg-white'>
                      <ProjectUserSelector project={project} onChangeUser={onChangeUser} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </main>
      </>
    )

  } else {
    return <Error message={'No projects found.'} />
  }

}

export default Users
