import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Leaderboard from '../Pages/Leaderboard/Leaderboard'
import TeamLeaderboard from '../Pages/Leaderboard/TeamLeaderboard'

export default function LeaderboardRoutes() {
  return (
    <Routes>
      <Route path='/' element={<Leaderboard />}>
        <Route path=':boardId' element={<Leaderboard />} />
      </Route>
    </Routes>
  )
}
