import { useQuery } from '@tanstack/react-query'
import { Loading, Error } from '../../Elements//Indicators'
import { Header1 } from '../../Elements//Type'
import { useState, useEffect } from 'react'
import { PageHeader } from '../../Elements//PageHeader'
import { useApi } from '../../AppContext'
import { useParams } from 'react-router-dom'

const TeamLeaderboard = ({ project, boardId }) => {
  const getStatsUri = (boardId, userId = null) => {
    return userId ? `/boards/${boardId}/stats/user/${userId}` : `/boards/${boardId}/stats/sprints`
  }
  const getQueryFn = (queryKey, uri) => {
    const fullUri = `${uri}${queryKey}`
    return async () => {
      const res = await fetch(fullUri)
      const data = await res.json()
      return data
    }
  }
  const analysisApiUri = useApi().analysis
  const [teamStats, setTeamStats] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (project && project.users) {
      setIsLoading(true)
      setTeamStats([])
      Promise.all(
        project.users.map(user => {
          const query = getStatsUri(boardId, user.id)
          return getQueryFn(query, analysisApiUri)().then(stats => ({
            userName: user.name,
            userId: user.id,
            stats
          }))
        })
      )
        .then(userStats => {
          const teamStats = userStats.map(user => {
            const statsFor2024 = user.stats.filter(stat => new Date(stat.endDate).getFullYear() === 2024)

            const totalResolvedBugs = statsFor2024.reduce((acc, stat) => acc + (Number.isFinite(stat.stats.resolvedBugs) ? stat.stats.resolvedBugs : 0), 0)
            const totalResolvedPoints = statsFor2024.reduce((acc, stat) => acc + (Number.isFinite(stat.stats.resolvedPoints) ? stat.stats.resolvedPoints : 0), 0)
            const totalStartBugs = statsFor2024.reduce((acc, stat) => acc + (Number.isFinite(stat.stats.startBugs) ? stat.stats.startBugs : 0), 0)
            const totalStartPoints = statsFor2024.reduce((acc, stat) => acc + (Number.isFinite(stat.stats.startPoints) ? stat.stats.startPoints : 0), 0)
            const pointsPerSprint = (totalResolvedPoints / statsFor2024.length).toFixed(2)
            const bugsPerSprint = (totalResolvedBugs / statsFor2024.length).toFixed(2)

            return {
              userName: user.userName,
              userId: user.userId,
              totalResolvedBugs,
              totalResolvedPoints,
              totalStartBugs,
              totalStartPoints,
              pointsPerSprint,
              bugsPerSprint
            }
          })
          const sortedTeamStats = [...teamStats].sort((a, b) => b.totalResolvedPoints - a.totalResolvedPoints)

          setTeamStats(sortedTeamStats)
          setIsLoading(false)
        })
    }
  }, [project, boardId])

  if (isLoading) {
    return <Loading message={'Loading user stats.'} />
  } else {

    const maxResolvedPoints = Math.max(...teamStats.map(stat => stat.totalResolvedPoints))
    const maxResolvedBugs = Math.max(...teamStats.map(stat => stat.totalResolvedBugs))
    const maxDeliveryRate = Math.max(...teamStats.map(stat => (stat.totalResolvedPoints / stat.totalStartPoints) * 100)).toFixed(2)
    return (
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      User Name
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Delivered Points
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Velocity
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Delivered Bugs
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {teamStats.map((teamStat, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {teamStat.totalResolvedPoints === maxResolvedPoints ? '🥇' : ''} {teamStat.userName}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {teamStat.totalResolvedPoints} of {teamStat.totalStartPoints}
                        ({((teamStat.totalResolvedPoints / teamStat.totalStartPoints) * 100).toFixed(2)}%
                        {((teamStat.totalResolvedPoints / teamStat.totalStartPoints) * 100).toFixed(2) == maxDeliveryRate ? '🥇' : ''})
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {teamStat.pointsPerSprint} points per sprint
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {teamStat.totalResolvedBugs} of {teamStat.totalStartBugs} bugs ({((teamStat.totalResolvedBugs / teamStat.totalStartBugs) * 100).toFixed(2)}%)
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TeamLeaderboard
