import { Link, useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Loading, Error } from '../../Elements//Indicators'
import { useParams } from 'react-router-dom'
import { PageHeader } from '../../Elements//PageHeader'
import { CheckBadgeIcon } from '@heroicons/react/20/solid'
import { Header2, Header3 } from '../../Elements//Type'
import ProjectUserSelector from './ProjectUserSeletor'
import { useApi } from '../../AppContext'

const Sprints = ({ projKey, boardId, sprints }) => {
  const numSprints = 9
  sprints = sprints.slice(0, numSprints)
  return (
    <div className='mb-4 grid grid-cols-1 gap-6 pb-4 sm:grid-cols-2 md:grid-cols-3'>
      {sprints.map(sprint => {
        const link = sprint.analysisAvailable
          ? `/projects/${projKey}/boards/${boardId}/sprints/${sprint.id}/analysis`
          : `/projects/${projKey}/boards/${boardId}/sprints/${sprint.id}`
        return (
          <Link
            key={sprint.id}
            className='col-span-1 grow rounded-lg bg-white p-4 shadow hover:bg-sky-100'
            to={link}
          >
            <div>
              <div className='grid grid-cols-3'>
                <div className='col-span-2'>
                  <p className='text-md basis-2/3 font-medium text-gray-900'>
                    {sprint.name}
                  </p>
                </div>
                {sprint.analysisAvailable && (
                  <div className='text-right'>
                    <span className='inline-flex items-center rounded-full bg-blue-100 px-3 py-0.5 text-sm font-medium text-blue-800'>
                      <CheckBadgeIcon
                        className='mr-1 h-4 w-4 text-white'
                        aria-hidden='true'
                      />
                      Analysis
                    </span>
                  </div>
                )}
              </div>
              <p className='mt-2 text-sm font-medium text-gray-500'>
                {sprint.goal}
              </p>
              {sprint.state != 'active' && (
                <p className='mt-2 text-sm text-gray-500'>
                  {new Date(sprint.startDate).toLocaleDateString('en-US', {
                    weekday: 'short',
                    day: 'numeric',
                    month: 'short'
                  })}{' '}
                  to{' '}
                  {new Date(sprint.endDate).toLocaleDateString('en-US', {
                    weekday: 'short',
                    day: 'numeric',
                    month: 'short'
                  })}
                </p>
              )}

              {sprint.state == 'active' && (
                <p className='mt-2 text-sm text-green-600'>
                  Active, started on{' '}
                  {new Date(sprint.startDate).toLocaleDateString('en-US', {
                    weekday: 'short',
                    day: 'numeric',
                    month: 'short'
                  })}
                </p>
              )}
            </div>
          </Link>
        )
      })}
    </div>
  )
}


const ProjectDetails = () => {
  const { projKey } = useParams()
  const navigate = useNavigate()
  const {
    isLoading: isLoadingProject,
    error: isErrorProject,
    data: project
  } = useQuery({
    queryKey: [`/projects/${projKey}`],
    staleTime: 600000
  })
  const boardId = project ? project.boards[0].id : null
  const analysisApiUri = useApi().analysis

  const analysisQueryFn = async ({ queryKey }) => {
    const res = await fetch(`${analysisApiUri}${queryKey[0]}`)
    const data = await res.json()
    return data
  }
  const {
    isLoading,
    error,
    data: board
  } = useQuery({
    queryKey: [`/boards/${boardId}`],
    staleTime: 600000,
    enabled: !!project,
    queryFn: analysisQueryFn
  })

  const onChangeUser = userId => {
    navigate(`/projects/${projKey}/users/report/${userId}`)
  }


  if (isLoading) {
    return <Loading message={`Loading project ${projKey}.`} />
  }

  if (error || isErrorProject) {
    return <Error title={'Error Loading Projects'} message={error.message} />
  }

  const headerConfig = {
    pageTitle: project.name,
    backLink: {
      label: 'Back',
      to: '/projects'
    },
    breadcrumb: [
      {
        label: 'Projects',
        to: '/projects'
      },
      {
        label: 'Project Details'
      }
    ]
  }

  return (
    <>
      <PageHeader config={headerConfig} />
      <main className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
        <Header2 title={board.name} />
        <Header3 title={'Sprints'} />
        <Sprints
          projKey={project.key}
          boardId={board.id}
          sprints={board.sprints}
        />
        <Header3 title={'Team Members'} />
        <ProjectUserSelector project={project} onChangeUser={onChangeUser} />
      </main>
    </>
  )
}

export default ProjectDetails
