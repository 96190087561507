import { Header3, PLarge, PSmallFaded } from './Type'

export const SimpleStats = ({ title, stats }) => {
  return (
    <div className='mb-4'>
      <Header3 title={title} />
      <dl className='mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3'>
        {stats.map(stat => (
          <div
            key={stat.name}
            className='overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6'
          >
            <dt className='truncate'>
              <PSmallFaded text={stat.name} />
            </dt>
            <dd className='mt-1'>
              <PLarge text={stat.value} />
            </dd>
          </div>
        ))}
      </dl>
    </div>
  )
}
