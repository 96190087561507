import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Dashboard from '../Pages/Dashboard/Dashboard'
import { TeamAnalysis } from '../Elements/Charts'

export default function DashboardRoutes() {
  return (
    <Routes>
      <Route path='/' element={<Dashboard />}>
        <Route path=':boardId' element={<TeamAnalysis />} />
      </Route>
    </Routes>
  )
}
