import { useParams } from 'react-router'
import { PageHeader } from '../../Elements/PageHeader'
import { UserAnalysis } from '../../Elements//Charts'
import { useQuery } from '@tanstack/react-query'

const UserUserDetails = () => {
  const { boardId, userId } = useParams()
  const {
    isLoading,
    error,
    data: users
  } = useQuery({
    queryKey: [`/boards/${boardId}/users`],
    staleTime: 600000
  })

  const headerConfig = {
    pageTitle: `User Report`,
    backLink: {
      label: 'Back',
      to: `/users`
    },
    breadcrumb: [
      {
        to: '/users',
        label: 'Users'
      },
      {
        label: 'User Report'
      }
    ]
  }

  if (users) {
    const user = users.find(user => user.id === userId)
    if (!user) {
      return <Error message={'User not found.'} />
    }
    return (
      <>
        <PageHeader config={headerConfig} />
        <main className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <UserAnalysis boardId={boardId} user={user} />
        </main>
      </>
    )
  }
  return null
}

export default UserUserDetails
