import { UserAnalysis } from '../../Elements//Charts'
import ProjectUserSelector from '../Projects/ProjectUserSeletor'

const UserReport = ({ onChangeUser, project, user }) => {

  console.log('User Report')
  console.log(project)

  return (
    <main className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
      <ProjectUserSelector onChangeUser={onChangeUser} project={project} user={user} />
      <div className='min-h-max rounded-lg'>
        <UserAnalysis projKey={project.key} boardId={project.boards[0].id} user={user} />
      </div>
    </main>
  )
}

export default UserReport
