import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { Header1 } from './Type'

export const BackNav = ({ link }) => {
  return (
    <nav className='sm:hidden' aria-label='Back'>
      <Link
        to={link.to}
        className='flex items-center text-sm font-medium text-gray-500 hover:text-gray-700'
      >
        <ChevronLeftIcon
          className='-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400'
          aria-hidden='true'
        />
        {link.label}
      </Link>
    </nav>
  )
}

export const Breadcrumb = ({ links }) => {
  return (
    <nav className='hidden sm:flex' aria-label='Breadcrumb'>
      <ol role='list' className='flex items-center space-x-4'>
        {links.map((link, index) => (
          <li key={`breadcrum-${index}`}>
            <div className='flex'>
              {index != 0 && (
                <ChevronRightIcon
                  className='h-5 w-5 flex-shrink-0 text-gray-400'
                  aria-hidden='true'
                />
              )}
              {link.to && (
                <Link
                  to={link.to}
                  className={clsx(
                    { 'ml-4': index != 0 },
                    'text-sm font-medium text-gray-500 hover:text-gray-700'
                  )}
                >
                  {link.label}
                </Link>
              )}
              {!link.to && (
                <a
                  href='#'
                  aria-current='page'
                  className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
                >
                  {link.label}
                </a>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}

export const PageHeader = ({ config }) => {
  return (
    <div className='mx-auto max-w-7xl sm:px-6 lg:px-8'>
      <div className='px-4 py-4 sm:px-0'>
        <div>
          <div>
            {config.backLink && <BackNav link={config.backLink} />}
            {config.breadcrumb && <Breadcrumb links={config.breadcrumb} />}
          </div>
          <div className='mt-2 md:flex md:items-center md:justify-between'>
            <div className='min-w-0 flex-1'>
              <Header1 title={config.pageTitle} />
            </div>
            <div className='mt-4 flex flex-shrink-0 md:mt-0 md:ml-4'>
              {config.rightComponent}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
